import React, { useState, useContext } from "react";
import pic from "../assets/doctor.jpg";
import { useAnimateContainer } from "../hooks/useAnimateContainer";
import validator from "validator";
import classNames from "classnames";
import { LanguageContext } from "../App";

const Contact = () => {
  const { languageContext } = useContext(LanguageContext);

  // State variables
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
  });
  const [noticeErr, setNoticeErr] = useState(false);
  const [notice, setNotice] = useState("");

  const { firstName, lastName, phoneNumber, email, message } = formData;

  // Update state for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validations
    if (!firstName) {
      showNoticeError("First Name is required", "Yêu cầu điền Tên");
      return;
    }
    if (!lastName) {
      showNoticeError("Last Name is required", "Yêu cầu điền Họ");
      return;
    }
    if (!phoneNumber) {
      showNoticeError("Phone Number is required", "Yêu cầu điền Số Điện Thoại");
      return;
    }
    if (email && !validator.isEmail(email)) {
      showNoticeError("Email entered is not valid", "Email không hợp lệ");
      return;
    }
    if (!validator.isMobilePhone(phoneNumber) || phoneNumber.length < 9) {
      showNoticeError(
        "Phone Number is not valid",
        "Số Điện Thoại không hợp lệ",
      );
      return;
    }

    try {
      const response = await fetch(
        "https://main-backend-jbsx.onrender.com/contacts",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...formData, status: "Pending" }),
        },
      );
      if (!response.ok) throw new Error("Failed to post contact");

      setNoticeErr(false);
      setNotice({
        english: "Your message has been sent!",
        vietnamese: "Tin nhắn của bạn đã được gửi",
      });
    } catch (err) {
      showNoticeError(
        "You can only submit 2 messages a minute!",
        "Bạn chỉ có thể gửi 2 tin nhắn trong 1 phút",
      );
      console.error("Failed to post contact", err);
    }
  };

  const showNoticeError = (english, vietnamese) => {
    setNoticeErr(true);
    setNotice({ english, vietnamese });
  };

  const animationRef2 = useAnimateContainer();

  // Helper function for form input fields
  const renderInputField = (name, type, placeholder, value) => (
    <input
      className="mb-5 h-16 w-full rounded-lg border-[0.0625rem] border-white bg-[rgba(255,255,255,.1)] 
        px-7 text-white placeholder-white outline-0 
        lg:h-12 lg:font-medium xl:h-14 2xl:h-16"
      type={type}
      name={name}
      value={value}
      onChange={handleInputChange}
      placeholder={placeholder}
    />
  );

  return (
    <div className="standard-mt relative mt-16 flex h-[54rem] justify-center bg-[#00378A] lg:h-[50rem] xl:h-[39.5rem] 2xl:h-[45rem]">
      {/* Container for the Form */}
      <div className="z-10 flex w-full max-w-[45rem] flex-col items-center lg:max-w-[45rem] lg:flex-row lg:items-stretch xl:max-w-[55rem] 2xl:max-w-[75rem]">
        {/* Form Section */}
        <div
          className="flex flex-col gap-5 px-4 py-8 sm:w-[90%] lg:w-[50%] lg:max-w-[22.5rem] lg:py-10 lg:pr-6 xl:max-w-[27.5rem] xl:py-16 
          2xl:max-w-[37.5rem] 2xl:pr-10"
        >
          <h6 className="text-white">
            {languageContext === "english" ? "Contact Us" : "Liên Hệ"}
          </h6>
          <h2 className="text-white">
            {languageContext === "english"
              ? "Get In Touch To Get More Information!"
              : "Tìm Hiểu Thêm Thông Tin"}
          </h2>
          <form
            className="flex flex-col"
            ref={animationRef2}
            onSubmit={handleSubmit}
            noValidate
          >
            <div className="mt-6 flex flex-col gap-x-5 xl:flex-row">
              {renderInputField(
                "firstName",
                "text",
                languageContext === "english" ? "First Name *" : "Tên *",
                firstName,
              )}
              {renderInputField(
                "lastName",
                "text",
                languageContext === "english" ? "Last Name *" : "Họ *",
                lastName,
              )}
            </div>
            <div className="flex flex-col gap-x-5 xl:flex-row">
              {renderInputField(
                "phoneNumber",
                "tel",
                languageContext === "english"
                  ? "Phone Number *"
                  : "Số Điện Thoại *",
                phoneNumber,
              )}
              {renderInputField("email", "email", "Email", email)}
            </div>
            <textarea
              className="h-36 resize-none overflow-hidden rounded-lg border-[0.0625rem] border-white 
              bg-[rgba(255,255,255,.1)] px-7 pt-3 text-white placeholder-white outline-0 lg:font-medium"
              name="message"
              value={message}
              onChange={handleInputChange}
              placeholder={
                languageContext === "english" ? "Message" : "Lời Nhắn"
              }
            />
            <div className="mt-10 flex flex-col items-center gap-5 lg:items-start xl:flex-row-reverse xl:justify-end">
              <p
                className={classNames(
                  "lg:max-w-36 xl:ml-6 xl:max-w-48 xl:text-end",
                  { "text-red-500": noticeErr },
                  { "text-green-500": !noticeErr },
                )}
              >
                {languageContext === "english"
                  ? notice.english
                  : notice.vietnamese}
              </p>
              <div className="flex flex-row xl:flex-col">
                <p className="text-white">
                  {languageContext === "english"
                    ? "Call us at"
                    : "Gọi chúng tôi"}{" "}
                  &nbsp;
                </p>
                <p className="text-white">(+84) 0911 229 111</p>
              </div>
              <input
                type="submit"
                value={languageContext === "english" ? "Send" : "Gửi"}
                className="h-16 w-[12rem] cursor-pointer rounded-[2rem] border-[0.1rem] border-white bg-white
              text-[#00378A] transition-colors duration-300 ease-in-out hover:bg-[#00378A] hover:text-white lg:h-10 lg:w-[8rem] lg:font-medium
          xl:h-12 2xl:h-16 2xl:w-[12rem]"
              />
            </div>
          </form>
        </div>
      </div>

      {/* Picture Section */}
      <div className="absolute right-0 top-0 hidden h-full w-1/2 lg:block lg:w-[50%]">
        <img src={pic} className="h-full w-full object-cover" alt="Doctor" />
      </div>
    </div>
  );
};

export default Contact;
