import React from "react";
import StaticNavBar from "../components/StaticNavBar";
import OrderSummary from "../components/OrderSummary";
import Footer from "../components/Footer";
import ScrollToTop from "../utils/ScrollToTop";
const OrderPage = () => {
  return (
    <div>
      <ScrollToTop />
      <StaticNavBar />
      <OrderSummary />
      <Footer />
    </div>
  );
};

export default OrderPage;
