import React from "react";
import { Link } from "react-router-dom";
import { isDesktop } from "react-device-detect";
import classNames from "classnames";
import { useContext } from "react";
import { LanguageContext } from "../App.js";

const ResearchList = ({ data }) => {
  const { languageContext } = useContext(LanguageContext);
  return (
    <div className="mx-4 mx-auto lg:max-w-[55rem]  2xl:max-w-[75rem]">
      <div className="standard-mt flex flex-col items-center pb-20">
        <div className="flex flex-col items-center justify-between md:col-span-2 md:col-start-1 lg:md:col-span-3 lg:w-full lg:flex-row">
          <h1 className="self-start leading-none">
            {languageContext === "english" ? (
              <>Recent Researches</>
            ) : (
              <>Nghiên Cứu Gần Đây</>
            )}
          </h1>
          <h6 className="text-[#838B93] md:w-[68%] lg:w-[33%] lg:text-end">
            {languageContext === "english" ? (
              <>
                Our latest and most trusted researchs to show effectiveness as
                well as efficacy.
              </>
            ) : (
              <>
                Những nghiên cứu lâm sàng để chứng minh độ hiệu quả của sản
                phẩm.
              </>
            )}
          </h6>
        </div>
      </div>
      <div
        className="flex flex-col flex-wrap gap-8 md:flex-row md:items-center
      md:justify-between md:gap-6 lg:grid lg:grid-cols-3 lg:gap-3 lg:gap-y-10 2xl:gap-8"
      >
        {data.map((research) => {
          return (
            <div
              className={classNames(
                "shadowContainer flex h-[36rem] flex-col items-start  gap-5 rounded-[0.625rem] border-2 p-5 text-start  sm:p-8 md:mx-0 md:w-[48%] lg:h-[23rem] lg:w-full  lg:p-3 2xl:h-[36rem] 2xl:p-5",
                { containerHover: isDesktop },
              )}
            >
              <img
                src={research.img}
                className="[20rem] w-full rounded object-cover lg:h-[12rem] xl:h-[14rem] 2xl:h-[20rem]"
              />
              <h4 className="text-[#00378A]">
                {languageContext === "english"
                  ? research.name.english
                  : research.name.vietnamese}
              </h4>
              <h6 className="h-16 text-ellipsis text-[#838B93] lg:h-10 xl:h-12 2xl:h-16">
                {languageContext === "english"
                  ? research.description.english
                  : research.description.vietnamese}
              </h6>
              <Link
                to={`${research.name.english.replaceAll(/\s/g, "-")}`}
                className="text-[#00378A]"
                state={research}
              >
                {languageContext === "english" ? (
                  <>Learn more</>
                ) : (
                  <>Tìm Hiểu Thêm</>
                )}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ResearchList;
