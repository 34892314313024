import React from "react";
import video from "../assets/introVideo.mp4";
import { useAnimateContainer } from "../hooks/useAnimateContainer";
import { LanguageContext } from "../App.js";
import { useContext } from "react";
import { Link } from "react-router-dom";

const Intro = ({ onVideoLoaded }) => {
  const { languageContext } = useContext(LanguageContext);
  const animateRef = useAnimateContainer();

  // Video load event handler
  const handleVideoLoaded = () => {
    onVideoLoaded(true); // Notify the parent component that the video has loaded
  };

  return (
    <div className="relative mb-[3.75rem] flex items-center justify-center">
      <div className="absolute"></div>
      <video
        loop
        muted
        autoPlay
        className="h-[46.5rem] w-auto min-w-full object-cover brightness-75
        lg:h-[30rem] xl:h-[32rem] 2xl:h-[45rem]"
        onCanPlay={handleVideoLoaded}
      >
        <source src={video} type="video/mp4" />
      </video>
      <div
        className="absolute z-10 flex flex-col items-center 
        justify-center gap-7 px-4 lg:w-full lg:max-w-[45rem] lg:items-start lg:pt-12 
        lg:text-start xl:max-w-[50rem] xl:pt-14 2xl:max-w-[75rem] 2xl:pt-8"
        ref={animateRef}
      >
        <h6 className="text-center text-white">
          {languageContext === "english"
            ? "Green Products for Viet Families"
            : "Dược Xanh Cho Sức Khỏe Mọi Nhà"}
        </h6>
        <h1 className="text-center text-white ">
          {languageContext === "english"
            ? "Phuong Minh Pharma"
            : "Dược Phương Minh"}
        </h1>
        <div className="h-[0.0625rem] w-3/4 bg-[#838b93] "></div>
        <p className="text-center text-white lg:w-[32rem] lg:text-start xl:w-[40rem]">
          {languageContext === "english"
            ? "Import and distribute Medicines, Supplements, and Cosmetics from top manufacturers in Europe. All products have been researched and clinically proven about efficacy and efficiency"
            : "Chuyên nhập khẩu, phân phối Thuốc, Thực Phẩm Chức Năng, Mỹ Phẩm của các hãng dược hàng đầu Châu Âu. Tất cả sản phẩm ddeuf được nghiên cứu lâm sàng về hiệu quả điều trị và tính an toàn."}
        </p>
        <Link
          to="/Products"
          className="shadowContainer p-auto flex h-16 w-[14rem] items-center justify-center rounded-[2rem] border-[0.1rem] bg-transparent text-white transition-colors duration-300 ease-in-out
        hover:border-none hover:bg-white hover:text-black lg:h-10 lg:w-[8.25rem] xl:h-12 xl:w-[9.5rem] 2xl:h-16 2xl:w-[14rem]"
        >
          {languageContext === "english" ? <>Order Now</> : <>Đặt Hàng Luôn</>}
        </Link>
      </div>
    </div>
  );
};

export default Intro;
