import React from "react";
import { isDesktop } from "react-device-detect";
import classNames from "classnames";
import { useAnimateContainer } from "../hooks/useAnimateContainer";
import { useContext } from "react";
import { LanguageContext } from "../App.js";
import { Link } from "react-router-dom";

const Research = ({ data }) => {
  const { languageContext } = useContext(LanguageContext);
  const animationRef = useAnimateContainer();
  return (
    <div className="standard-mt flex flex-col items-center text-center">
      <div
        className="flex flex-col items-center gap-5 p-10 pt-0"
        ref={animationRef}
      >
        <h6 className="text-[#00378A]">
          {languageContext === "english" ? <>Researches</> : <>Nghiên Cứu</>}
        </h6>
        <h2 className="lg:max-w-[30rem] 2xl:max-w-[50rem]">
          {languageContext === "english" ? (
            <>
              Our latest and most trusted researches to show effectiveness as
              well as efficacy.
            </>
          ) : (
            <>
              Những nghiên cứu lâm sàng để chứng minh độ hiệu quả của sản phẩm.
            </>
          )}
        </h2>
      </div>
      <div
        className={classNames(
          "mx-4 grid gap-8 lg:max-w-[45rem] lg:gap-3 xl:max-w-[55rem] 2xl:max-w-[75rem]",
          {
            "grid-cols-1": data.length === 1, // Single column for one research
            "grid-cols-1 md:grid-cols-2": data.length === 2, // Two columns for two researches
            "grid-cols-1 md:grid-cols-2 lg:grid-cols-3": data.length >= 3, // Three columns for three or more researches
          },
        )}
      >
        {data.slice(0, 3).map((item, i) => {
          return (
            <div
              className={classNames(
                "flex flex-col items-start gap-5 rounded-[0.625rem] border-2 p-5 sm:p-8  md:mx-0 lg:p-3 2xl:p-5",
                "shadowContainer h-full w-full", // Ensure the item spans full width
                { containerHover: isDesktop },
              )}
            >
              <img
                src={item.img}
                className=" h-[20rem] w-full rounded object-cover lg:h-[12rem] xl:h-[14rem] 2xl:h-[20rem]"
              />
              <h4 className="text-[#00378A]">
                {languageContext === "english"
                  ? item.name.english
                  : item.name.vietnamese}
              </h4>
              <h6 className="h-16 text-ellipsis text-[#838B93] lg:h-10 xl:h-12 2xl:h-16">
                {languageContext === "english"
                  ? item.description.english
                  : item.description.vietnamese}
              </h6>
              <Link
                to={`/Insights/Researches/${item.name.english.replaceAll(/\s/g, "-")}`}
                className="text-[#00378A]"
                state={item}
              >
                {languageContext === "english" ? (
                  <>Learn more</>
                ) : (
                  <>Tìm Hiểu Thêm</>
                )}
              </Link>{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Research;
