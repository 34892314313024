import React from "react";
import { Link } from "react-router-dom";
import { isDesktop } from "react-device-detect";
import pic from "../assets/research2.jpg";
import classNames from "classnames";
import { useContext } from "react";
import { CartContext } from "../App.js";
import { LanguageContext } from "../App.js";
import { useNavigate } from "react-router-dom";

const ProductsList = ({ data }) => {
  const navigate = useNavigate();
  const { languageContext } = useContext(LanguageContext);
  const { handleAddToCart } = useContext(CartContext);
  const handleAddItem = (e) => {
    e.preventDefault();
    const currentProductCart = JSON.parse(localStorage.getItem("productCart"));
    if (
      !localStorage
        .getItem("productCart")
        .includes(e.target.getAttribute("data-productId"))
    ) {
      const newItem = {
        itemQuantity: 1,
        itemId: e.target.getAttribute("data-productId"),
        itemNameEnglish: e.target.getAttribute("data-productNameEnglish"),
        itemNameVietnamese: e.target.getAttribute("data-productNameVietnamese"),
        itemSubtotal: e.target.getAttribute("data-productPrice"),
        itemTotal: e.target.getAttribute("data-productPromotionPrice"),
        itemImg: e.target.getAttribute("data-productImg"),
      };
      currentProductCart.cartList.push(newItem);
    } else {
      const updatedItem = currentProductCart.cartList.find(
        ({ itemId }) => itemId === e.target.getAttribute("data-productId"),
      );

      const updatedIdx = currentProductCart.cartList.findIndex(
        ({ itemId }) => itemId === e.target.getAttribute("data-productId"),
      );
      currentProductCart.cartList[updatedIdx].itemQuantity =
        updatedItem.itemQuantity + 1;
    }
    currentProductCart.quantity += 1;
    currentProductCart.total =
      Number(currentProductCart.total) +
      Number(e.target.getAttribute("data-productPromotionPrice"));
    currentProductCart.subtotal =
      Number(currentProductCart.subtotal) +
      Number(e.target.getAttribute("data-productPrice"));
    handleAddToCart(currentProductCart);
  };
  const handleBuyNow = (e) => {
    e.preventDefault();
    const currentProductCart = JSON.parse(localStorage.getItem("productCart"));
    if (
      !localStorage
        .getItem("productCart")
        .includes(e.target.getAttribute("data-productId"))
    ) {
      const newItem = {
        itemQuantity: 1,
        itemId: e.target.getAttribute("data-productId"),
        itemNameEnglish: e.target.getAttribute("data-productNameEnglish"),
        itemNameVietnamese: e.target.getAttribute("data-productNameVietnamese"),
        itemSubtotal: e.target.getAttribute("data-productPrice"),
        itemTotal: e.target.getAttribute("data-productPromotionPrice"),
        itemImg: e.target.getAttribute("data-productImg"),
      };
      currentProductCart.cartList.push(newItem);
    } else {
      const updatedItem = currentProductCart.cartList.find(
        ({ itemId }) => itemId === e.target.getAttribute("data-productId"),
      );

      const updatedIdx = currentProductCart.cartList.findIndex(
        ({ itemId }) => itemId === e.target.getAttribute("data-productId"),
      );
      currentProductCart.cartList[updatedIdx].itemQuantity =
        updatedItem.itemQuantity + 1;
    }
    currentProductCart.quantity += 1;
    currentProductCart.total =
      Number(currentProductCart.total) +
      Number(e.target.getAttribute("data-productPromotionPrice"));
    currentProductCart.subtotal =
      Number(currentProductCart.subtotal) +
      Number(e.target.getAttribute("data-productPrice"));
    handleAddToCart(currentProductCart);
    navigate("/Order");
  };
  return (
    <div className="standard-mt flex flex-col items-center">
      <div className="mx-4 flex max-w-[75rem] flex-col gap-8 md:grid md:grid-cols-2 lg:grid-cols-4">
        <div className="flex flex-col items-center justify-between md:col-span-2 md:col-start-1 lg:md:col-span-4 lg:flex-row">
          <h1 className="self-start leading-none">
            {languageContext === "english" ? <>Products</> : <>Sản Phẩm</>}
          </h1>
          <p className="text-[#838B93] md:w-[68%] lg:w-[33%] lg:text-end">
            {languageContext === "english" ? (
              <>
                Descrription Descrription Descrription Descrription Descrription
                Descrription Descrription Descrription
              </>
            ) : (
              <>
                Mô tả tiêu đề Mô tả tiêu đề Mô tả tiêu đề Mô tả tiêu đề Mô tả
                tiêu đề Mô tả tiêu đề Mô tả tiêu đề
              </>
            )}
          </p>
        </div>
        {data.map((product) => {
          return (
            <div
              className="group relative flex h-[26rem] flex-col items-center
                   justify-center rounded-[1.25rem] border-[0.0625rem] bg-cover
                   bg-center bg-no-repeat px-0 py-8 lg:h-[16rem] xl:h-[18rem] 2xl:h-[20rem]"
              style={{
                backgroundImage: `url(${product.img || pic})`,
              }}
            >
              <div
                className={classNames(
                  {
                    "absolute inset-0 rounded-[1.25rem] bg-[#6D52FF] opacity-30 transition-opacity duration-[0.7s] ease-in-out group-hover:opacity-75":
                      isDesktop,
                  },
                  {
                    "absolute inset-0 rounded-[1.25rem] bg-[#6D52FF] opacity-30":
                      !isDesktop,
                  },
                )}
              ></div>
              <div className="z-10 mt-10 flex flex-col items-center">
                <h5
                  className={classNames("relative text-white", {
                    "translate-y-10 transition-transform duration-500 ease-in-out group-hover:translate-y-0":
                      isDesktop,
                  })}
                >
                  {languageContext === "english"
                    ? product.name?.english || ""
                    : product.name?.vietnamese || ""}
                </h5>

                <Link
                  className={classNames("text-white", {
                    "forwards translate-y-4 opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-100":
                      isDesktop,
                  })}
                  to={`/Products/${product.name?.english.replaceAll(/\s/g, "-") || ""}`}
                >
                  {languageContext === "english" ? (
                    <>Read more</>
                  ) : (
                    <>Tìm hiểu thêm</>
                  )}
                </Link>

                <div className="mt-14 flex flex-col items-center justify-between gap-2">
                  <h5
                    onClick={handleAddItem}
                    data-productId={product?._id || ""}
                    data-productNameEnglish={product?.name.english || ""}
                    data-productNameVietnamese={product.name?.vietnamese || ""}
                    data-productPrice={product?.price || ""}
                    data-productPromotionPrice={product?.promotionPrice || ""}
                    data-productImg={product?.img || ""}
                    className="cursor-pointer text-nowrap rounded-[0.625rem] px-4 text-[1.1rem] text-white underline"
                  >
                    {languageContext === "english" ? (
                      <>Add To Cart</>
                    ) : (
                      <>Thêm Vào Giỏ</>
                    )}
                  </h5>
                  <h5
                    onClick={handleBuyNow}
                    data-productId={product?._id || ""}
                    data-productNameEnglish={product?.name.english || ""}
                    data-productNameVietnamese={product.name?.vietnamese || ""}
                    data-productPrice={product?.price || ""}
                    data-productPromotionPrice={product?.promotionPrice || ""}
                    data-productImg={product?.img || ""}
                    className="cursor-pointer text-nowrap rounded-[0.625rem] px-4 text-[1.1rem] text-white underline"
                    to={"/AboutUs/FAQ"}
                  >
                    {languageContext === "english" ? (
                      <>Buy Now</>
                    ) : (
                      <>Mua luôn</>
                    )}
                  </h5>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductsList;
