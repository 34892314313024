import React from "react";
import pic from "../assets/pharmacist.jpg";
import { useAnimateContainer } from "../hooks/useAnimateContainer";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { LanguageContext } from "../App.js";

const About = () => {
  const { languageContext } = useContext(LanguageContext);
  const animationRef1 = useAnimateContainer();
  const animationRef2 = useAnimateContainer();
  return (
    <div
      className="shadowContainer standard-mt relative mx-4 flex flex-col gap-10 rounded-[0.625rem] border-2 pb-5 
      pt-10  lg:mx-auto lg:h-[20rem] lg:max-w-[45rem] lg:flex-row lg:px-5 lg:pt-5
    xl:h-[25rem] xl:max-w-[55rem] 2xl:h-[32rem] 2xl:max-w-[75rem] 2xl:gap-20 2xl:px-8 2xl:pt-6"
    >
      <div
        className="flex flex-col items-center gap-5 pt-2 lg:mr-0 lg:w-[40rem]  lg:items-start lg:gap-3 lg:pt-4 lg:text-start xl:w-[56rem] 
      xl:gap-5 2xl:pt-6"
        ref={animationRef1}
      >
        <h6 className="text-[#00378A]">
          {languageContext === "english" ? "About Us" : "Giới Thiệu"}
        </h6>
        <h2 className="font-medium">
          {languageContext === "english" ? (
            <>Over 20 Years if Experience</>
          ) : (
            <>Hơn 20 Năm Kinh Nghiệm trong Ngành</>
          )}
        </h2>
        <h6>
          {languageContext === "english" ? (
            <>
              Products related to humans must be “Perfect”. Therefore, we choose
              companies that continuously invest in research to improve the
              safety and effectiveness of each product before launching.
              Therefore, all products we distribute to Vietnam have full
              clinical research.
            </>
          ) : (
            <>
              Các sản phẩm liên quan đến con người phải “Hoàn hảo”. Vì vậy chúng
              tôi lựa chọn các công ty đầu tư liên tục vào nghiên cứu nhằm cải
              thiện tính an toàn, hiệu quả cho từng sản phẩm trước khi ra đời.
              Do đó, tất cả các sản phẩm chúng tôi phân phối về Việt Nam đều có
              đầy đủ nghiên cứu lâm sàng.
            </>
          )}
        </h6>
        <Link
          to="/AboutUs"
          className="shadowContainer absolute bottom-4 flex h-16 items-center justify-center rounded-[2rem] border-[0.1rem] px-8 py-3 transition-colors duration-300
        ease-in-out hover:bg-[#00378A] hover:text-white lg:h-10 xl:h-12 2xl:h-16 "
        >
          {languageContext === "english" ? <>Learn More</> : <>Xem Thêm</>}
        </Link>
      </div>
      <div
        style={{ backgroundImage: `url(${pic})` }}
        className="mx-5 flex h-[20rem]  w-auto flex-col justify-between rounded bg-cover bg-center
         bg-no-repeat text-start md:mx-[12%] lg:m-0 lg:h-full lg:w-full"
      >
        <div
          className="mt-6 flex flex-col items-start pl-7"
          ref={animationRef2}
        >
          <h2 className="font-semibold text-white">300+</h2>
          <h6 className="text-white">
            {languageContext === "english" ? <>Certificates</> : <>Chứng Chỉ</>}
          </h6>
          <h2 className="font-semibold text-white">300+</h2>
          <h6 className="text-white">
            {languageContext === "english" ? <>Awards</> : <>Giải Thưởng</>}
          </h6>
        </div>
        <div className="flex h-[25%] items-center pl-7 backdrop-blur-sm">
          <h6 className="text-white">
            {languageContext === "english" ? (
              <>We always value our customers</>
            ) : (
              <>Chúng tôi luôn đặt khác hàng lên đầu</>
            )}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default About;
