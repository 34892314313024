import React from "react";
// import { data } from "../data/ArticlesData";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import { useContext } from "react";
import { LanguageContext } from "../App.js";

const ArticlesList = ({ data }) => {
  const { languageContext } = useContext(LanguageContext);
  const navRef = useRef(null);
  //Numbers of Navigation Bar pivot and Navigation Bar length and each page length
  // (length > pivot)
  const currentPivot = 3;
  let navBarLength = 6;
  const pageLength = 6;
  if (navBarLength >= Math.ceil(data.length / pageLength)) {
    navBarLength = Math.ceil(data.length / pageLength);
  }
  const navList = Array.from(
    { length: Math.ceil(data.length / pageLength) },
    (_, i) => i + 1,
  );

  const [currentNavList, setCurrentNavList] = useState([]);
  const [currentNav, setCurrentNav] = useState(1);
  const handleNavigation = (e) => {
    setCurrentNav(Number(e.target.textContent));
  };
  useEffect(() => {
    if (currentNav <= currentPivot) {
      setCurrentNavList(
        navList
          .slice(0, navBarLength - 1)
          .concat([navList[navList.length - 1]]),
      );
    } else if (currentNav >= navList.length - (navBarLength - currentPivot)) {
      setCurrentNavList(
        [navList[0]].concat(navList.slice(navList.length - navBarLength + 1)),
      );
    } else {
      setCurrentNavList(
        [navList[0]]
          .concat(
            navList.slice(
              currentNav - currentPivot + 1,
              currentNav + (navBarLength - currentPivot) - 1,
            ),
          )
          .concat([navList[navList.length - 1]]),
      );
    }
    return () => {};
  }, [currentNav]);
  return (
    <div className="standard-mt mx-4 mx-auto flex max-w-[75rem] flex-col gap-10 pb-10 lg:max-w-[55rem]  xl:mx-auto 2xl:max-w-[75rem]">
      <div className="flex items-center justify-between">
        <h1 className="self-start leading-none">
          {languageContext === "english" ? (
            <>Recent Articles</>
          ) : (
            <>Tin Tức Gần Đây</>
          )}
        </h1>
        <h6 className="text-[#838B93] md:w-[68%] lg:w-[33%] lg:text-end">
          {languageContext === "english" ? (
            <>
              Nam eget magna arcu. Morbi molestie, quam cursus eleifend
              interdum, lorem ante tempor nibh, efficitur dictum metus arcu vel
              sapien. Vivamus in ligula
            </>
          ) : (
            <>
              Các nhà máy đạt tiêu chuẩn GMP của Guna, 0.F.I… với diện tích lên
              tới 6000m2. hệ thống Quản lý chất lượng tích hợp có cấu trúc đảm
              bảo tiêu chuẩn ISO 9001:2015.
            </>
          )}
        </h6>
      </div>
      <div
        className="fflex flex-col flex-wrap gap-8 md:flex-row md:items-center
      md:justify-between md:gap-6 lg:grid lg:grid-cols-3 lg:gap-3 lg:gap-y-10 2xl:gap-8"
      >
        {data
          .slice((currentNav - 1) * pageLength, currentNav * pageLength)
          .map((article) => {
            return (
              <div
                className={classNames(
                  "shadowContainer flex h-[36rem] flex-col items-start  gap-5 rounded-[0.625rem] border-2 p-5 text-start  sm:p-8 md:mx-0 md:w-[48%] lg:h-[23rem] lg:w-full  lg:p-3 2xl:h-[36rem] 2xl:p-5",
                  { containerHover: isDesktop },
                )}
              >
                <img
                  src={article.img}
                  className=" h-[20rem] w-full rounded object-cover lg:h-[12rem] xl:h-[14rem] 2xl:h-[20rem]"
                />
                <h4 className="text-[#00378A]">
                  {languageContext === "english"
                    ? article.name.english
                    : article.name.vietnamese}
                </h4>
                <h6 className="h-16 text-ellipsis text-ellipsis text-[#838B93] text-[#838B93] lg:h-10 xl:h-12 2xl:h-16">
                  {languageContext === "english"
                    ? article.description.english
                    : article.description.vietnamese}
                </h6>
                <Link
                  to={`/Insights/Articles/${article.name.english}`.replaceAll(
                    /\s/g,
                    "-",
                  )}
                  className="text-[#00378A]"
                  state={article}
                >
                  {languageContext === "english" ? (
                    <>Learn More</>
                  ) : (
                    <>Xem Thêm</>
                  )}
                </Link>
              </div>
            );
          })}
      </div>
      {currentNavList.length > 1 && (
        <div className="flex justify-center gap-8 lg:self-end" ref={navRef}>
          {currentNavList.map((item) => {
            return (
              <h4
                className={
                  item === currentNav
                    ? "cursor-pointer text-[#00378A]"
                    : "cursor-pointer text-[#838B93]"
                }
                onClick={handleNavigation}
              >
                {item}
              </h4>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ArticlesList;
