import React from "react";
import { useAnimateContainer } from "../hooks/useAnimateContainer";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import { useContext } from "react";
import { LanguageContext } from "../App.js";
import { Link } from "react-router-dom";

const Articles = ({ data }) => {
  const { languageContext } = useContext(LanguageContext);
  const animationRef1 = useAnimateContainer();
  const animationRef2 = useAnimateContainer();

  return (
    <div className="standard-mt w-full">
      {/* Header Section */}
      <div
        className="flex flex-col items-center gap-5 pb-10 text-center"
        ref={animationRef1}
      >
        <h6 className="text-[#00378A]">
          {languageContext === "english" ? "Articles" : "Tin Tức"}
        </h6>
        <h2 className="max-w-[50rem]">
          {languageContext === "english"
            ? "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            : "Tin tức và sự kiện nổi bật của tập đoàn"}
        </h2>
      </div>

      {/* Articles Section */}
      <div className="flex flex-col items-center gap-8" ref={animationRef2}>
        {data.slice(0, 3).map((item, index) => (
          <div
            key={index}
            className={classNames(
              "shadowContainer mx-4 flex w-full flex-col justify-between gap-6 rounded-[0.625rem] border-2 p-5 sm:p-8 md:flex-row md:text-start lg:max-w-[45rem] lg:gap-5 lg:p-5 2xl:max-w-[60rem]",
              { containerHover: isDesktop },
            )}
          >
            {/* Article Image */}
            <img
              src={item.img}
              alt={
                languageContext === "english"
                  ? item.name.english
                  : item.name.vietnamese
              }
              className="h-[16rem] w-full rounded-md object-cover md:h-auto md:w-[40%] lg:w-[35%]"
            />

            {/* Article Content */}
            <div className="flex flex-col gap-6 md:w-[58%] lg:w-[60%]">
              {/* Article Title */}
              <h2 className="text-[#00378A]">
                {languageContext === "english"
                  ? item.name.english
                  : item.name.vietnamese}
              </h2>

              {/* Article Description */}
              <h6 className="text-[#838B93]">
                {languageContext === "english"
                  ? item.description.english
                  : item.description.vietnamese}
              </h6>

              {/* Learn More Link */}
              <Link
                to={`/Insights/Articles/${item.name.english}`.replaceAll(
                  /\s/g,
                  "-",
                )}
                className="mt-auto text-[#00378A] hover:underline"
                state={item}
              >
                {languageContext === "english" ? "Learn More" : "Xem Thêm"}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Articles;
